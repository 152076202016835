/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../api/partnerHttpService";
import {
	isEmpty,
	getDateXDaysFromNow,
	newDateFormatter,
} from "../utils/helpers/functions";
import {
	TOKENKEY,
	USERKEY,
	PARTNERPROFILEPHOTOKEY,
	MEMBERPROFILEPHOTOKEY,
	ROLES,
	ROLEKEY,
} from "../utils/constants";
import { getCurrentUser } from "../api/common/auth";

export const login = createAsyncThunk(
	"userAuth/login",
	// eslint-disable-next-line consistent-return
	async (payload, { rejectWithValue }) => {
		try {
			const response = await axios.post("/api/login", payload);
			localStorage.setItem(TOKENKEY, response.data.data.accessToken);
			localStorage.setItem(USERKEY, JSON.stringify(response.data.data));
			return response.data;
		} catch (error) {
			if (error.response) {
				return rejectWithValue(error.response.data.message);
			}
			if (error.request) {
				return rejectWithValue("Network Error");
			}
			if (error.message) {
				return rejectWithValue(error.message);
			}
		}
	}
);

const authSlice = createSlice({
	name: "userAuth",
	initialState: {
		isAuthenticated: false,
		isProcessing: false,
		isLoggingOut: false,
		user: {},
		role: "",
		region: {
			name: "Nigeria",
			region: "NG",
			region_logo: "https://doc.smartdeals.com.ng/files/region/NG/region.jpg",
			iso_name: "NGA",
			list: 1,
		},
		message: "",
		error: "",
		bizFrom: "SMARTDEALS",
	},
	reducers: {
		logout(state, action) {
			state.isProcessing = false;
			state.isLoggingOut = false;
			state.isAuthenticated = false;
			state.user = {};
			localStorage.clear();
			window.location = "/login";
		},
		loginSuccess(state, action) {
			state.isProcessing = false;
			state.isAuthenticated = !isEmpty(action.payload);
			state.user = action.payload;
			state.message = "";
			state.error = "";
			state.message = action.payload.message;
			// state.region?.region = action.payload.region || "NG";
			// state.region?.region_logo =
			// 	action.payload.region_logo ||
			// 	"https://doc.smartdeals.com.ng/files/region/NG/region.jpg";
			state.role = action.payload.role;
			localStorage.setItem(ROLEKEY, ROLES.PARTNER);
		},
		setRegion(state, action) {
			state.region.name = action.payload.name;
			state.region.region = action.payload.short_name;
			state.region.region_logo = action.payload.logo;
			state.region.iso_name = action.payload.iso_name;
			state.region.list = action.payload.list;
		},
		setBizfrom(state, action) {
			state.bizFrom = action.payload;
		},
		setRole(state, action) {
			state.role = action.payload;
		},
		updateProfilePhoto(state, action) {
			if (state.role === ROLES.PARTNER) {
				state.user[PARTNERPROFILEPHOTOKEY] = action.payload;
			}
			if (state.role === ROLES.MEMBER) {
				state.user[MEMBERPROFILEPHOTOKEY] = action.payload;
			}
		},
		updateUserData(state, action) {
			state.user = action.payload;
		},
		updateWalletBalance(state, action) {
			state.user.walletBalance = action.payload;
		},
		updateSubscription(state, action) {
			const currentUser = getCurrentUser(USERKEY);
			const newExpiryDate = getDateXDaysFromNow(
				Number(action.payload?.subscriptionDays)
			);
			const formattedExpiryDate = newDateFormatter(
				newExpiryDate,
				"yyyy-MM-dd hh:mm:ss"
			);
			localStorage.setItem(
				USERKEY,
				JSON.stringify({
					...currentUser,
					points: Number(currentUser?.points) + Number(action.payload?.points),
					subscriptionClass: action.payload?.subscriptionClass,
					subscriptionDays: action.payload?.subscriptionDays,
					subscriptionStatus: 1,
					expiry: formattedExpiryDate,
				})
			);
			state.user = {
				...state.user,
				points: Number(currentUser?.points) + Number(action.payload?.points),
				subscriptionClass: action.payload?.subscriptionClass,
				subscriptionDays: action.payload?.subscriptionDays,
				subscriptionStatus: 1,
				expiry: formattedExpiryDate,
			};
		},
		updateFirstLogin(state, action) {
			state.user.firstLogin = action.payload;
		},
		updateUserPointsBalance(state, action) {
			state.user.points = action.payload;
			const currentUser = getCurrentUser(USERKEY);
			JSON.stringify({
				...currentUser,
				points: action.payload,
			});
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.pending, (state, action) => {
				state.isProcessing = true;
				state.error = null;
			})
			.addCase(login.fulfilled, (state, action) => {
				state.isProcessing = false;
				state.isAuthenticated = !isEmpty(action.payload.data);
				state.error = null;
				state.user = action.payload.data;
				state.message = action.payload.message;
				localStorage.setItem(ROLEKEY, ROLES.PARTNER);
				// state.region?.region = action.payload.data.region;
				// state.region?.region_logo = action.payload.data.region_logo;
			})
			.addCase(login.rejected, (state, action) => {
				state.isProcessing = false;
				state.error = action.payload;
			});
	},
});

export const {
	logout,
	loginSuccess,
	setRegion,
	setBizfrom,
	setRole,
	updateProfilePhoto,
	updateUserData,
	updateWalletBalance,
	updateSubscription,
	updateFirstLogin,
	updateUserPointsBalance,
} = authSlice.actions;

export default authSlice.reducer;
