// import React, { lazy, Suspense } from "react";
// import { Route, Routes, useLocation, Navigate } from "react-router-dom";
// import { AnimatePresence } from "framer-motion";
// import Layout from "../../components/common/Layout";
// // import ScrollToTop from "../../components/common/ScrollToTop";
// import Loader from "../../components/common/FullSpinLoader";

// // Use lazy loading for components
// const Home = lazy(() => import("../views/Home"));
// const Partners = lazy(() => import("../views/Partners"));
// const ContactUs = lazy(() => import("../views/ContactUs"));
// const NotFound = lazy(() => import("../views/NotFoundPage/NotFoundPage"));
// const Rsvp = lazy(() => import("../views/Rsvp"));
// const PayWithSmartDeals = lazy(() => import("../views/PayWithSmartDeals"));
// const EventReport = lazy(() => import("../views/EventReport"));
// const DeleteAccount = lazy(() => import("../views/DeleteAccount"));
// const ReferralReport = lazy(() => import("../views/ReferralReport"));

// const BaseRoute = () => {
// 	const location = useLocation();

// 	return (
// 		<AnimatePresence mode="wait" location={location} key={location.pathname}>
// 			{/* <ScrollToTop> */}
// 			<Routes>
// 				<Route path="/" element={<Layout />}>
// 					<Route
// 						index
// 						element={
// 							<Suspense fallback={<Loader />}>
// 								<Home />
// 							</Suspense>
// 						}
// 					/>
// 					<Route
// 						path="/signup"
// 						element={
// 							<Suspense fallback={<Loader />}>
// 								<Navigate to="/" />
// 							</Suspense>
// 						}
// 					/>
// 					<Route
// 						path="/partners"
// 						element={
// 							<Suspense fallback={<Loader />}>
// 								<Partners />
// 							</Suspense>
// 						}
// 					/>
// 					<Route
// 						path="/contact-us"
// 						element={
// 							<Suspense fallback={<Loader />}>
// 								<ContactUs />
// 							</Suspense>
// 						}
// 					/>
// 					<Route
// 						path="/rsvp"
// 						element={
// 							<Suspense fallback={<Loader />}>
// 								<Rsvp />
// 							</Suspense>
// 						}
// 					/>
// 					<Route
// 						path="/pay-with-smartdeals"
// 						element={
// 							<Suspense fallback={<Loader />}>
// 								<PayWithSmartDeals />
// 							</Suspense>
// 						}
// 					/>
// 					<Route
// 						path="/event-report"
// 						element={
// 							<Suspense fallback={<Loader />}>
// 								<EventReport />
// 							</Suspense>
// 						}
// 					/>
// 					<Route
// 						path="/delete-account"
// 						element={
// 							<Suspense fallback={<Loader />}>
// 								<DeleteAccount />
// 							</Suspense>
// 						}
// 					/>
// 					<Route
// 						path="/referrer-report"
// 						element={
// 							<Suspense fallback={<Loader />}>
// 								<ReferralReport />
// 							</Suspense>
// 						}
// 					/>
// 				</Route>

// 				<Route
// 					path="*"
// 					element={
// 						<Suspense fallback={<Loader />}>
// 							<NotFound />
// 						</Suspense>
// 					}
// 				/>
// 			</Routes>
// 			{/* </ScrollToTop> */}
// 		</AnimatePresence>
// 	);
// };

// export default BaseRoute;


import React, { lazy, Suspense } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Layout from "../../components/common/Layout";
// import ScrollToTop from "../../components/common/ScrollToTop";
import Loader from "../../components/common/FullSpinLoader";
import ProtectedRoutes from "../../components/common/ProtectedRoutes";
import Logout from "../../components/common/Logout";
import { useSelector } from "react-redux";

// Use lazy loading for components
const Home = lazy(() => import("../views/Home"));
const Partners = lazy(() => import("../views/Partners"));
const ContactUs = lazy(() => import("../views/ContactUs"));
const NotFound = lazy(() => import("../views/NotFoundPage/NotFoundPage"));
const Rsvp = lazy(() => import("../views/Rsvp"));
const PayWithSmartDeals = lazy(() => import("../views/PayWithSmartDeals"));
const EventReport = lazy(() => import("../views/EventReport"));
const DeleteAccount = lazy(() => import("../views/DeleteAccount"));
const ReferralReport = lazy(() => import("../views/ReferralReport"));
const BusinessSummary = lazy(() => import("../views/BusinessSummary"));
const Login = lazy(() => import("../views/Login"));
const PartnerLogin = lazy(() => import("../views/PartnerLogin"));
const PartnerVerifyUser = lazy(() => import("../views/PartnerVerifyUser"));
const PartnerLayout = lazy(() => import("../../components/User/PartnerLayout"));
const PartnerDetails = lazy(() => import("../views/PartnerDetails"));
const PartnerClaimDiscount = lazy(() =>
	import("../views/PartnerClaimDiscount")
);
const PartnerDiscountHistory = lazy(() =>
	import("../views/PartnerDiscountHistory")
);
const PartnerChangePassword = lazy(() =>
	import("../views/PartnerChangePassword")
);
const ForgotPassword = lazy(() => import("../views/ForgotPassword"));
const NGXHomepage = lazy(() => import("../views/NGX/NGXHomepage"));
const NGXMonitor = lazy(() => import("../views/NGX/Monitor"));
const NGXStockRecommendations = lazy(() =>
	import("../views/NGX/StockRecommendations")
);
const AccountStatement = lazy(() => import("../views/AccountStatement"));

const BaseRoute = () => {
	const location = useLocation();
	const { isAuthenticated } = useSelector((state) => state.auth);

	const { isAuthenticated: userIsAuthenticated } = useSelector(
		(state) => state.userAuth
	);

	return (
		<AnimatePresence mode="wait" location={location} key={location.pathname}>
			{/* <ScrollToTop> */}
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route
						index
						element={
							<Suspense fallback={<Loader />}>
								<Home />
							</Suspense>
						}
					/>
					<Route
						path="/signup"
						element={
							<Suspense fallback={<Loader />}>
								<Navigate to="/" />
							</Suspense>
						}
					/>
					<Route
						path="/partners"
						element={
							<Suspense fallback={<Loader />}>
								<Partners />
							</Suspense>
						}
					/>
					<Route
						path="/contact-us"
						element={
							<Suspense fallback={<Loader />}>
								<ContactUs />
							</Suspense>
						}
					/>
					<Route
						path="/rsvp"
						element={
							<Suspense fallback={<Loader />}>
								<Rsvp />
							</Suspense>
						}
					/>
					<Route
						path="/pay-with-smartdeals"
						element={
							<Suspense fallback={<Loader />}>
								<PayWithSmartDeals />
							</Suspense>
						}
					/>
					<Route
						path="/event-report"
						element={
							<Suspense fallback={<Loader />}>
								<EventReport />
							</Suspense>
						}
					/>
					<Route
						path="/statement"
						element={
							<Suspense fallback={<Loader />}>
								<AccountStatement />
							</Suspense>
						}
					/>
					<Route
						path="/delete-account"
						element={
							<Suspense fallback={<Loader />}>
								<DeleteAccount />
							</Suspense>
						}
					/>
					<Route
						path="/referrer-report"
						element={
							<Suspense fallback={<Loader />}>
								<ReferralReport />
							</Suspense>
						}
					/>
					<Route
						path="/business-summary"
						element={
							<Suspense fallback={<Loader />}>
								<ProtectedRoutes isAllowed={isAuthenticated}>
									<BusinessSummary />
								</ProtectedRoutes>
							</Suspense>
						}
					/>

					{/* partner discount */}
					<Route
						path="partner/login"
						element={
							<Suspense fallback={<Loader />}>
								<PartnerLogin />
							</Suspense>
						}
					/>
					<Route
						path="partner/forgot-password"
						element={
							<Suspense fallback={<Loader />}>
								<ForgotPassword />
							</Suspense>
						}
					/>
					<Route
						path="partner"
						element={
							<Suspense fallback={<Loader />}>
								<ProtectedRoutes
									isAllowed={userIsAuthenticated}
									redirectPath="/partner/login"
								>
									<PartnerLayout />
								</ProtectedRoutes>
							</Suspense>
						}
					>
						<Route
							index
							element={
								<ProtectedRoutes
									isAllowed={userIsAuthenticated}
									redirectPath="/partner/login"
								>
									<PartnerVerifyUser />
								</ProtectedRoutes>
							}
						/>
						<Route
							path="details"
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoutes
										isAllowed={userIsAuthenticated}
										redirectPath="/partner/login"
									>
										<PartnerDetails />
									</ProtectedRoutes>
								</Suspense>
							}
						/>
						<Route
							path="claim-discount"
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoutes
										isAllowed={userIsAuthenticated}
										redirectPath="/partner/login"
									>
										<PartnerClaimDiscount />
									</ProtectedRoutes>
								</Suspense>
							}
						/>
						<Route
							path="discount-history"
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoutes
										isAllowed={userIsAuthenticated}
										redirectPath="/partner/login"
									>
										<PartnerDiscountHistory />
									</ProtectedRoutes>
								</Suspense>
							}
						/>
						<Route
							path="change-password"
							element={
								<Suspense fallback={<Loader />}>
									<ProtectedRoutes
										isAllowed={userIsAuthenticated}
										redirectPath="/partner/login"
									>
										<PartnerChangePassword />
									</ProtectedRoutes>
								</Suspense>
							}
						/>
					</Route>

					<Route
						path="/login"
						element={
							<Suspense fallback={<Loader />}>
								<Login />
							</Suspense>
						}
					/>
					<Route
						path="/logout"
						element={
							<Suspense fallback={<Loader />}>
								<Logout />
							</Suspense>
						}
					/>
					<Route
						path="/ngx_ai"
						element={
							<Suspense fallback={<Loader />}>
								<NGXHomepage />
							</Suspense>
						}
					/>
					<Route
						path="/ngx_monitor"
						element={
							<Suspense fallback={<Loader />}>
								<NGXMonitor />
							</Suspense>
						}
					/>
					<Route
						path="/ngx_stock_recommendations"
						element={
							<Suspense fallback={<Loader />}>
								<NGXStockRecommendations />
							</Suspense>
						}
					/>
				</Route>

				<Route
					path="*"
					element={
						<Suspense fallback={<Loader />}>
							<NotFound />
						</Suspense>
					}
				/>
			</Routes>
			{/* </ScrollToTop> */}
		</AnimatePresence>
	);
};

export default BaseRoute;
