import {
	DiscountIcon,
	AddIcCallIcon,
	WifiIcon,
	ElectricBoltIcon,
	LiveTvIcon,
	EventAvailableIcon,
} from "../components/common/muiComponents";

const specialCharacterCheck = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
const digitCheck = /[0-9]/;
const uppercaseCheck = /[A-Z]/;
const TOKENKEY = "accessToken";
const USERKEY = "user";
const ROLEKEY = "role";
const REGION_KEY = "region";
const ACCESS_CODE_KEY = "accessCode";
const VERIFIED_USER_DETAILS = "verifiedUserDetails";

const PARTNERPROFILEPHOTOKEY = "partnerLogo";
const MEMBERPROFILEPHOTOKEY = "photo";

const ROLES = {
	ADMIN: "admin",
	MEMBER: "member",
	PARTNER: "partner",
	CUSTOMER: "customer",
	EVENT: "event",
};

const currencySymbolMap = {
	NG: "₦",
	KE: "Ksh",
	GH: "GH₵",
	ZA: "ZAR",
	ZM: "ZK",
};

const drawerWidth = 230;

const DEVICETYPE = "WEB";

const PROFILEPHOTOKEY = "partnerLogo";

const PAYMENTOPTIONS = {
	CARD: "card",
	USSD: "ussd",
	BANK: "bank",
	BANKTRANSFER: "bank_transfer",
};

const featuresArray = [
	{
		title: "Discount Access",
		description:
			"Enjoy great discounts from our discount partners across various sectors nationwide.",
		icon: <DiscountIcon color="primary" />,
	},
	{
		title: "Airtime Purchase",
		description:
			"Instantly purchase airtime top-up from all mobile networks in Nigeria via our interactive and user-friendly app.",
		icon: <AddIcCallIcon color="primary" />,
	},
	{
		title: "Data Bundle Purchase",
		description:
			"Instantly purchase data bundles from all mobile networks in Nigeria via our interactive and user-friendly app.",
		icon: <WifiIcon color="primary" />,
	},
	{
		title: "Electricity Bills",
		description:
			"It's easy to forget to pay your electricity bills due to your busy schedule. SmartDeals bill payment service got you covered. Our bill payment solution helps you purchase prepaid electricity unit and settle your postpaid bills instantly.",
		icon: <ElectricBoltIcon color="primary" />,
	},
	{
		title: "Cable TV Bills",
		description:
			"Our seamless bill payment process helps you pay for Cable TV to make sure you never miss out on your favorite shows.",
		icon: <LiveTvIcon color="primary" />,
	},
	{
		title: "Event Management",
		description:
			"We designed an event management system that enable you create events (free and paid) in a few minutes. The system also helps with guest check-in and management at the venue of the event.",
		icon: <EventAvailableIcon color="primary" />,
	},
];

export {
	specialCharacterCheck,
	digitCheck,
	uppercaseCheck,
	TOKENKEY,
	USERKEY,
	ROLEKEY,
	ROLES,
	currencySymbolMap,
	drawerWidth,
	DEVICETYPE,
	PROFILEPHOTOKEY,
	PAYMENTOPTIONS,
	REGION_KEY,
	featuresArray,
	ACCESS_CODE_KEY,
	PARTNERPROFILEPHOTOKEY,
	MEMBERPROFILEPHOTOKEY,
	VERIFIED_USER_DETAILS,
};
