import { Outlet } from "react-router-dom";

import { Box } from "./muiComponents";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Layout = () => {
	return (
		<div>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					minHeight: "100vh",
					width: "100%",
					maxWidth: "100vw",
				}}
			>
				<Navbar />
				<Outlet />
			</Box>
			<Footer />
		</div>
	);
};

export default Layout;
