import styled from "styled-components";

import { Typography, Box } from "../common/muiComponents";
import phoneImages from "../../assets/images/phone-images.png";
import googlePlayBadge from "../../assets/logos/google-play-badge.png";
import appleStoreBadge from "../../assets/logos/app-store.png";

const mainTextStyle = {
	textAlign: { xs: "center", md: "start" },
	marginBottom: { md: "2rem", xs: "1rem" },
	wordWrap: "break-word",
};

const leftMainStyle = {
	width: "30rem",
	maxWidth: "80%",
};

const FooterImages = () => {
	return (
		<MainDiv>
			<Box sx={leftMainStyle}>
				<Typography variant="h4" component="h2" sx={mainTextStyle}>
					Join SmartDeals today on iOS and Android
				</Typography>

				<BadgesContainer>
					<a
						href="https://play.google.com/store/apps/details?id=com.makvid.smartdeals"
						target="_blank"
						rel="noreferrer"
					>
						<GooglePlayBadgeImage src={googlePlayBadge} />
					</a>
					<a
						href="https://apps.apple.com/us/app/smartdeals-app/id1645730774"
						target="_blank"
						rel="noreferrer"
					>
						<BadgeImage src={appleStoreBadge} />
					</a>
				</BadgesContainer>
			</Box>

			<PhoneImages src={phoneImages} alt="app view" />
		</MainDiv>
	);
};

export default FooterImages;

const MainDiv = styled.div`
	padding: 3rem;
	display: flex;
	justify-content: center;
	gap: 5rem;
	@media (max-width: 768px) {
		padding: 1rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`;

const PhoneImages = styled.img`
	width: 30rem;
	max-width: 80%;
`;

const BadgesContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	margin-top: 1rem;
	max-width: 100vw;
	@media (max-width: 768px) {
		justify-content: center;
	}
`;

const BadgeImage = styled.img`
	width: 10rem;
	padding: 0;
	margin: 0;
	object-fit: cover;
`;

const GooglePlayBadgeImage = styled(BadgeImage)`
	width: 9rem;
`;
