import * as React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	useTheme,
	Slide,
	useMediaQuery,
} from "../common/muiComponents";
import { useModal } from "../../hooks/ModalContext";
import FooterImages from "./FooterImages";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
	const { isModalOpen, closeModal } = useModal();

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<div>
			<Dialog
				open={isModalOpen}
				TransitionComponent={Transition}
				keepMounted
				onClose={closeModal}
				aria-describedby="alert-dialog-slide-description"
				fullScreen={fullScreen}
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: { md: "20px", xs: 0 },
						maxWidth: { md: "80vw !important", xs: "100vw" },
					},
				}}
			>
				<DialogContent>
					<FooterImages />
				</DialogContent>
				<DialogActions>
					<Button onClick={closeModal}>close</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
