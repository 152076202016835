const env = process.env.NODE_ENV || "development";

console.log({ cor: process.env.REACT_APP_ENVIRONMENT });

let cache;

const config = () => {
	if (!cache) {
		cache = Object.freeze({
			env,
			secrets: {
				apiHost:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? "https://core-staging.smartdeals.com.ng"
						: "https://core.smartdeals.com.ng",
				encryptionPassword:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? process.env.REACT_APP_ENCRYPTION_PASSWORD_DEV
						: process.env.REACT_APP_ENCRYPTION_PASSWORD,
				encryptionSecretKey:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? process.env.REACT_APP_ENCRYPTION_SECRETKEY_DEV
						: process.env.REACT_APP_ENCRYPTION_SECRETKEY,
				docsClient:
					process.env.REACT_APP_ENVIRONMENT === "development"
						? "https://docs-staging.smartdeals.com.ng"
						: "https://docs.smartdeals.com.ng",
				googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
				hashKey: process.env.REACT_APP_HASH_KEY,
				payPassword: process.env.REACT_APP_PAY_PASSWORD,
				paySecret: process.env.REACT_APP_PAY_SECRET,
			},
		});
	}
	return cache;
};

export default config;
