import { useState } from "react";
import { Link } from "react-router-dom";

import {
	AppBar,
	Box,
	Toolbar,
	IconButton,
	Button,
	useMediaQuery,
	useTheme,
	MenuItem,
	Typography,
	Menu,
	MenuIcon,
	Link as MuiLink,
} from "./muiComponents";
import { useModal } from "../../hooks/ModalContext";
import config from "../../config";
import logo from "../../assets/logos/smart-deals-logo.svg";
import arrowRight from "../../assets/icons/arrow-right.svg";
const { docsClient } = config().secrets;

const style = {
	backgroundColor: "primary.main",
	paddingBottom: "1rem",
	paddingTop: "1rem",
};

const toolbarStyle = {
	borderRadius: "3.125rem",
	backgroundColor: "#fff",
	display: "flex",
	justifyContent: "space-between",
	border: "1px solid #e41f26",
};

const buttonStyle = {
	textTransform: "none",
	color: "#1E1E1E",
	fontFamily: "Roboto",
	borderRadius: "1.875rem",
	border: "1px solid red",
};

const pages = [
	{ title: "home", path: "/", isExternal: false },
	{ title: "partners", path: "/partners", isExternal: false },
	{
		title: "help center",
		path: "/help-center",
		isExternal: true,
		externalLink: docsClient,
	},
	{ title: "FAQ", path: "/?scrollTo=faq", isExternal: false },
	{ title: "contact", path: "/contact-us", isExternal: false },
];

export default function ButtonAppBar() {
	const [anchorElNav, setAnchorElNav] = useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const theme = useTheme();
	const bigDisplay = useMediaQuery(theme.breakpoints.up("md"));
	const { openModal } = useModal();

	return (
		<Box
			sx={{
				flexGrow: 1,
			}}
		>
			<AppBar position="fixed" sx={style} elevation={0}>
				<Toolbar sx={toolbarStyle}>
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="open drawer"
						sx={{ mr: 2 }}
						component={Link}
						to="/"
						disableRipple
					>
						<Box sx={{ height: "2rem" }}>
							<img
								src={logo}
								height="100%"
								width="100%"
								alt="Smart Deals Logo"
							/>
						</Box>
					</IconButton>

					<Box
						sx={{
							flexGrow: 1,
							display: { xs: "none", md: "flex" },
							justifyContent: "flex-end",
						}}
					>
						{pages.map((page) => (
							<Button
								key={page.title}
								component={page.isExternal ? MuiLink : Link}
								{...(page.isExternal
									? {
											href: `${page.externalLink}${page.path}`,
											// target: "_blank",
											// rel: "noopener noreferrer",
									  }
									: { to: `${page.path}` })}
								sx={{
									// my: 2,
									display: "flex",
									textTransform: "capitalize",
									color: "#000",
									// fontFamily: "roboto",
									justifyContent: "center",
									fontSize: "1rem",
								}}
								disableRipple
								disableFocusRipple
							>
								{page.title}
							</Button>
						))}

						<Button variant="outlined" sx={buttonStyle} onClick={openModal}>
							Download app{" "}
							{bigDisplay && (
								<img
									src={arrowRight}
									alt="arrow right icon"
									style={{ marginLeft: "0.5rem" }}
								/>
							)}
						</Button>
					</Box>

					{/* small screen */}
					<Box
						sx={{
							flexGrow: 1,
							display: { xs: "flex", md: "none" },
							justifyContent: "flex-end",
						}}
					>
						<IconButton
							size="large"
							aria-label="menu appbar button"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="default"
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: "block", md: "none" },
							}}
						>
							{pages.map((page) => (
								<MenuItem
									key={page.title}
									component={page.isExternal ? MuiLink : Link}
									{...(page.isExternal
										? {
												href: `${page.externalLink}${page.path}`,
												// target: "_blank",
												// rel: "noopener noreferrer",
										  }
										: { to: `${page.path}` })}
									onClick={handleCloseNavMenu}
								>
									<Typography textAlign="center" textTransform="capitalize">
										{page.title}
									</Typography>
								</MenuItem>
							))}
							<MenuItem
								onClick={() => {
									openModal();
									handleCloseNavMenu();
								}}
							>
								<Typography textAlign="center" textTransform="none">
									Download app
								</Typography>
							</MenuItem>
						</Menu>
					</Box>
				</Toolbar>
			</AppBar>
		</Box>
	);
}
