import { createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

import config from "../config";
// import chimeAudioFile from "../assets/audio/chime.mp3";

const { chatSocketUrl } = config().secrets;

export const socket = io.connect(chatSocketUrl, {
	transports: ["websocket", "polling", "flashsocket"],
});

export const SocketContext = createContext();

const SupportSocketProvider = (props) => {
	const [clientId, setClientId] = useState("");
	const [stocks, setStocks] = useState([]);
	const [stocksLoading, setStocksLoading] = useState(false);
	const [bidlevels, setBidLevels] = useState([]);
	const [offerLevels, setOfferLevels] = useState([]);
	const [trades, setTrades] = useState([]);
	const [bidOfferTradesLoading, setBidOfferTradesLoading] = useState(false);
	const [stockRecommendations, setStockRecommendations] = useState([]);
	const [stockRecommendationsTitle, setStockRecommendationsTitle] =
		useState("");
	const [stockRecommendationsLoading, setStockRecommendationsLoading] =
		useState(false);
	const [stockRecommendationsPrice, setStockRecommendationsPrice] = useState(
		[]
	);
	const [stockRecommendationsPriceTitle, setStockRecommendationsPriceTitle] =
		useState("");
	const [
		stockRecommendationsPriceLoading,
		setStockRecommendationsPriceLoading,
	] = useState(false);

	const updateStockPrices = () => {
		setStocksLoading(true);
		const payload = { username: "NGX", accountType: "NGX" };
		socket.emit("stock_prices", payload);
	};

	const updateStockRecommendations = () => {
		setStockRecommendationsLoading(true);
		const payload = { username: "NGX", accountType: "NGX" };

		socket.emit("stock_recommendations", payload);
	};

	const updateStockRecommendationsPrice = () => {
		setStockRecommendationsPriceLoading(true);
		const payload = { username: "NGX", accountType: "NGX" };

		socket.emit("stock_recommendations_price", payload);
	};

	useEffect(() => {
		socket.on("connect", () => {
			console.log(`Connection opened on : ${socket.id}`);
		});

		socket.on("connected", (message) => {
			setClientId(socket.id);
			console.log(message);
			const response = JSON.parse(message);

			if (response.method === "connected" && response.clientId === clientId) {
				console.log(`Client id Set successfully ${clientId}`);
			}
		});

		socket.on("stock_prices", (message) => {
			const response = JSON.parse(message);
			setStocksLoading(false);
			setStocks(response?.data || []);
		});

		socket.on("bid_offer", (message) => {
			setBidOfferTradesLoading(false);
			const response = JSON.parse(message);
			const parsedResponse = JSON.parse(response);
			setBidLevels(parsedResponse?.bidLevels || []);
			setOfferLevels(parsedResponse?.offerLevels || []);
			setTrades(parsedResponse?.trades || []);
		});

		socket.on("stock_recommendations", (message) => {
			setStockRecommendationsLoading(false);
			const response = JSON.parse(message);
			setStockRecommendations(response?.data || []);
			setStockRecommendationsTitle(response?.title);
		});

		socket.on("stock_recommendations_price", (message) => {
			setStockRecommendationsPriceLoading(false);
			const response = JSON.parse(message);
			setStockRecommendationsPrice(response?.data || []);
			setStockRecommendationsPriceTitle(response?.title);
		});

		updateStockPrices();
		updateStockRecommendations();
		updateStockRecommendationsPrice();

		return () => {
			socket.off("stock_prices");
			socket.off("bid_offer");
			socket.off("stock_recommendations");
			socket.off("stock_recommendations_price");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket]);

	return (
		<SocketContext.Provider
			value={{
				socket,
				clientId,
				stocks,
				stocksLoading,
				bidlevels,
				offerLevels,
				trades,
				bidOfferTradesLoading,
				setBidOfferTradesLoading,
				stockRecommendations,
				stockRecommendationsPrice,
				stockRecommendationsLoading,
				stockRecommendationsPriceLoading,
				stockRecommendationsTitle,
				stockRecommendationsPriceTitle,
			}}
		>
			{props.children}
		</SocketContext.Provider>
	);
};

export default SupportSocketProvider;
