import { ACCESS_CODE_KEY, ROLEKEY, TOKENKEY } from "../constants";
import secureLocalStorage from "react-secure-storage";

const auth = {
	authenticate: () => {
		const accessCode = secureLocalStorage.getItem(ACCESS_CODE_KEY);
		if (accessCode) {
			return true;
		}
		return false;
	},
	setAccessCode: (accessCode) => {
		secureLocalStorage.setItem(ACCESS_CODE_KEY, accessCode);
	},
	getAccessCode: () => {
		return secureLocalStorage.getItem(ACCESS_CODE_KEY);
	},

	userAuthenticate: () => {
		const token = localStorage.getItem(TOKENKEY);
		if (token) {
			return true;
		}
		return false;
	},
	getUserRole: () => {
		const role = localStorage.getItem(ROLEKEY);
		if (role) {
			return role;
		}
		return "";
	},
};

export default auth;
