import axios from "axios";
import { toast } from "react-toastify";
import { TOKENKEY } from "../utils/constants";
import config from "../config";
import { encryption, decryption } from "../utils/helpers/functions";

const { apiHost } = config().secrets;

const customAxios = axios.create({
	baseURL: `${apiHost}`,
	timeout: 30000,
});

const role = "member";
const bizFrom = "SMARTDEALS";

const region = { short_name: "NG", region: "NG" };

const defaultParams = {
	region: region?.region || region?.short_name,
	bizFrom,
	accountType: role,
	deviceType: "WEB",
};

const defaultData = {
	region: region?.region || region?.short_name,
	bizFrom,
	accountType: role,
	deviceType: "WEB",
};

const mergeObjects = (obj1, obj2) => {
	return {
		...obj1,
		...obj2,
	};
};

const requestHandler = (request) => {
	request.headers.Authorization = localStorage.getItem(TOKENKEY);

	if (request.method === "get") {
		// merge default params with request params
		const mergeParams = mergeObjects(defaultParams, request.params);
		request.params = {
			payload: encryption(mergeParams),
		};
	} else if (request.method === "post") {
		// merge default data with request data
		const mergedData = mergeObjects(defaultData, request.data);

		request.data = encryption(mergedData);

		request.headers["Content-Type"] = "text/plain";
	}

	return request;
};

const responseHandler = (response) => {
	if (response?.status === 403) {
		localStorage.clear();
		window.location = "/";
	}
	let reponseData;
	try {
		reponseData = decryption(response?.data);
	} catch (err) {
		reponseData = response?.data;
	}
	return { ...response, data: reponseData };
};

const errorHandler = (error) => {
	if (error.response?.status === 403) {
		localStorage.clear();
		window.location = "/";
	}
	if (error.response?.data) {
		const decryptedData = decryption(error.response.data);
		if (decryptedData?.message?.toLowerCase() === "invalid credentials") {
			toast.error(decryptedData?.message);
			localStorage.clear();
			window.location = "/partner/login";
		}
		// eslint-disable-next-line no-param-reassign
		error.response.data = decryptedData;
	}

	// eslint-disable-next-line prefer-promise-reject-errors
	return Promise.reject(error);
};

customAxios.interceptors.request.use(
	(request) => requestHandler(request),
	(error) => errorHandler(error)
);

customAxios.interceptors.response.use(
	(response) => responseHandler(response),
	(error) => errorHandler(error)
);

export default customAxios;
